import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Bounce from 'react-reveal/Bounce'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'
import Hero from '../components/Hero'
import '../components/all.sass'
import PrivatePolicy from '../components/PrivatePolicy'
import runnerWithLetter from '../img/rush-13.png'
// import Carousel from '../components/Carousel'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      surname: '',
      phone: '',
      email: '',
      subject: '',
      other: '',
      message: '',

      isSubjectDisabled: false,
      isOtherSubjectDisabled: false,
      isAgree: false,
      isSend: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubjectInputChange = event => {
    const { value } = event.target

    if (value) {
      this.setState({ isOtherSubjectDisabled: true })
    } else {
      this.setState({ isOtherSubjectDisabled: false })
    }

    this.handleInputChange(event)
  }

  handleOtherSubjectInputChange = event => {
    const { value } = event.target

    if (value) {
      this.setState({ isSubjectDisabled: true })
    } else {
      this.setState({ isSubjectDisabled: false })
    }

    this.handleInputChange(event)
  }

  toggleAgreeCheckbox = () => {
    this.setState({ isAgree: !this.state.isAgree })
  }

  handleSubmit = async event => {
    event.preventDefault()

    const { intl } = this.props

    if (!this.state.isAgree) {
      toast.error(intl.messages.markAgreement)
      return
    }

    this.setState({ isSend: true })

    const formValues = {
      firstname: this.state.firstname,
      surname: this.state.surname,
      phone: this.state.phone,
      email: this.state.email,
      subject: this.state.subject,
      other: this.state.other,
      message: this.state.message,
    }

    const formData = new FormData()

    for (const name in formValues) {
      formData.append(name, formValues[name])
    }

    const url = `https://${process.env.GATSBY_baseUrl}/wp-json/contact-form-7/v1/contact-forms/67/feedback`
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    })

    if (response.ok) {
      this.setState({
        firstname: '',
        surname: '',
        phone: '',
        email: '',
        subject: '',
        other: '',
        message: '',
      })

      toast.success(intl.messages.messageSendSuccessfully)
    } else {
      if (!isConfirmed) {
        toast.error(intl.messages.markAgreement)
        return
      }
    }
  }

  render() {
    const { data, pageContext, intl } = this.props
    const {
      firstname,
      surname,
      phone,
      email,
      subject,
      other,
      message,
      isSubjectDisabled,
      isOtherSubjectDisabled,
      isAgree,
      subjectOptions,
    } = this.state
    // TODO
    return (
      <Layout>
        <section className="section-bubble1">
          <section className="section">
            <div className="container m-top">
              <Bounce left>
                <h1 className="title rainbow-underline">
                  {intl.messages.contact}
                </h1>
                <div className="columns">
                  <div
                    className="column is-4"
                    style={{
                      backgroundImage: `url(${runnerWithLetter})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      //, minHeight: '400px', minWidth: '300px',
                    }}
                  ></div>
                  <div className="column is-8">
                    <form method="post" onSubmit={this.handleSubmit}>
                      <div className="columns">
                        <div className="column is-half">
                          <div className="field">
                            <label
                              className="label is-medium"
                              htmlFor="firstname"
                            >
                              {intl.messages.firstname}
                            </label>
                            <div className="control">
                              <input
                                className="input is-medium"
                                type="text"
                                placeholder="Jan"
                                value={firstname}
                                id="firstname"
                                name="firstname"
                                onChange={this.handleInputChange}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="column is-half">
                          <div className="field">
                            <label
                              className="label is-medium"
                              htmlFor="surname"
                            >
                              {intl.messages.lastname}
                            </label>
                            <div className="control">
                              <input
                                className="input is-medium"
                                type="text"
                                placeholder="Kowalski"
                                id="surname"
                                value={surname}
                                name="surname"
                                onChange={this.handleInputChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column is-half">
                          <div className="field">
                            <label className="label is-medium" htmlFor="email">
                              {intl.messages.email}
                            </label>
                            <div className="control">
                              {/* is-danger */}
                              <input
                                className="input is-medium"
                                type="email"
                                placeholder="email@email.com"
                                id="email"
                                value={email}
                                name="email"
                                onChange={this.handleInputChange}
                                required
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                              </span>
                              <span className="icon is-small is-right">
                                <i className="fas fa-exclamation-triangle"></i>
                              </span>
                            </div>
                            {/* <p className="help is-danger">Niepoprawny format</p> */}
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="field">
                            <label className="label is-medium" htmlFor="phone">
                              {intl.messages.phone}
                            </label>
                            <div className="control">
                              <input
                                className="input is-medium"
                                type="tel"
                                placeholder="+48 555 555 555"
                                id="phone"
                                name="phone"
                                pattern="\+?[0-9]{0,14}$"
                                required
                                onChange={this.handleInputChange}
                                value={phone}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column is-half">
                          <div className="field">
                            <label
                              className="label is-medium"
                              htmlFor="subject"
                            >
                              {intl.messages.iAmInterested}
                            </label>
                            <div className="control">
                              <div className="select is-medium">
                                <select
                                  value={subject}
                                  onChange={this.handleSubjectInputChange}
                                  id="subject"
                                  name="subject"
                                  disabled={isSubjectDisabled}
                                  required={!this.state.other}
                                >
                                  <option defaultValue></option>
                                  {data.allWpKontaktOpcja.edges.map(
                                    (option, i) => {
                                      let optionTranslation = option.node.title

                                      if (
                                        intl.locale === 'en' &&
                                        option.node.enTranslation?.entitle
                                      ) {
                                        optionTranslation =
                                          option.node.enTranslation.entitle
                                      }
                                      return (
                                        <option key={i}>
                                          {optionTranslation}
                                        </option>
                                      )
                                    }
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column is-half">
                          <div className="field">
                            <label className="label is-medium" htmlFor="other">
                              {intl.messages.others}
                            </label>
                            <div className="control">
                              <input
                                className="input is-medium"
                                type="text"
                                id="other"
                                name="other"
                                value={other}
                                disabled={isOtherSubjectDisabled}
                                required={!this.state.subject}
                                onChange={this.handleOtherSubjectInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label is-medium" htmlFor="message">
                          {intl.messages.message}
                        </label>
                        <div className="control">
                          <textarea
                            className="textarea is-medium"
                            rows="10"
                            id="message"
                            name="message"
                            value={message}
                            onChange={this.handleInputChange}
                            placeholder={`Max 400 ${intl.messages.signs}`}
                          ></textarea>
                        </div>
                      </div>

                      <div className="field">
                        <div className="control">
                          <div className="page__toggle">
                            <label className="checkbox is-medium toggle">
                              <input
                                type="checkbox"
                                className="toggle__input"
                                id="isAgree"
                                name="isAgree"
                                value={isAgree}
                                onChange={this.toggleAgreeCheckbox}
                                // required
                              />
                              <span className="toggle__label">
                                <span className="toggle__text">
                                  {intl.messages.privatePolicyLabel + ' '}
                                  <a
                                    href={intl.messages.regulationsUrl}
                                    target="_blank"
                                  >
                                    {intl.messages.processignOfPersonalData}
                                  </a>
                                </span>
                              </span>
                            </label>
                            {/* <span class={`${!this.state.isAgree && this.state.isSend ? "validation-error" : ''}`}>Zgoda na przetwarzanie danych jest wymagana</span> */}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button type="submit" className="button-shaking">
                          {intl.messages.send}
                        </button>
                      </div>
                    </form>
                    <ToastContainer />
                  </div>
                </div>
              </Bounce>
            </div>

            <PrivatePolicy />
          </section>
        </section>
      </Layout>
    )
  }
}

Contact.propTypes = {}

export default injectIntl(Contact)
export const query = graphql`
  query {
    allWpKontaktOpcja {
      edges {
        node {
          title
          slug
          enTranslation {
            entitle
          }
        }
      }
    }
  }
`
