import React from 'react'
import AgreeModal from './AgreeModal'

function PrivatePolicy() {
  return (
    <AgreeModal
      title="Polityka prywatności"
      body={'lorem ipsum...fdsfsfdfdssfdsfd'}
    />
  )
}

export default PrivatePolicy
