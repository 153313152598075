import React from 'react'

function AgreeModal({ title, body }) {
  return (
    <div className="modal">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" aria-label="close"></button>
        </header>
        <section className="modal-card-body">{body}</section>
        <footer className="modal-card-foot">
          <button className="button">Anuluj</button>
        </footer>
      </div>
    </div>
  )
}

export default AgreeModal
